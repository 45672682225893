:root {
    --header-color: rgb(66, 120, 245);
    --description-color: rgb(75, 78, 80);
    --background-color: #f5f5f5;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    width: 100%;
    background-color: white;
    margin-top: 4rem;
}

.contact h3 {
    color: var(--header-color);
}

.contact form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.contact form input {
    width: 20%;
    margin: 0.8em;
    padding: 1em;
    border-radius: 1em;
    border-color: black;
    background-color: #f5f5f5;
    box-shadow: 0 0 1em rgb(209, 205, 205);
}

.contact textarea {
    width: 20%;
    margin: 0.8em;
    padding: 1em;
    border-radius: 1em;
    border-color: black;
    background-color: #f5f5f5;
    box-shadow: 0 0 1em rgb(209, 205, 205);
    resize: none;
    height: 5rem;
}

.contact button {
    margin: 1em;
    width: 8em;
    height: 2em;
    border-color: var(--header-color);
    background-color: white;
    cursor: pointer;
    color: var(--header-color);
    border-radius: 1em;
    font-size: 1rem;
    transition: 0.5s;
}

.contact button:hover {
    background: linear-gradient(to left, var(--header-color) 34%, white 65%) right;
    background-size: 300% 100%;
    color: white;
}

@media only screen and (max-width: 490px) {
    .contact form input {
        width: 80%;
    }

    .contact textarea {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .contact form input {
        width: 80%;
    }

    .contact textarea {
        width: 80%;
    }
}

@media only screen and (max-width: 992px) {
    .contact form input {
        width: 80%;
    }

    .contact textarea {
        width: 80%;
    }
}


