:root {
    --header-color: rgb(66, 120, 245);
    --description-color: rgb(75, 78, 80);
    --background-color: #f5f5f5;
}

.nav {
    position: fixed;
    display: grid;
    grid-template-columns: 80% 5% 5% 5% 5%;
    align-items: center;
    padding: 0.5em;
    width: 100%;
    transition: 0.5s;
    padding-left: 1rem;
}

.nav a {
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-position 275ms ease;
    background: linear-gradient(to right, var(--header-color), var(--header-color) 50%, black 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
}

.nav a:hover {
    background-position: 0 100%;

}

.nav-scrolled {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, .09);
    transition: 0.5s;
}

@media only screen and (max-width: 490px) {
    .nav {
        grid-template-columns: 40% 20% 20% 20%;
    }

    .nav h2 {
        font-size: 4vw;
    }

    .nav a {
        font-size: 3vw;
    }
}


@media only screen and (max-width: 768px) {
    .nav {
        grid-template-columns: 40% 20% 20% 20%;
    }
}

@media only screen and (max-width: 992px) {
    .nav {
        grid-template-columns: 40% 20% 20% 20%;
    }
}