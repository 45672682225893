.home-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.home-about-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.home-about-blurb {
    width: 40%;
}

.home-about-blurb h1 {
    font-size: 3rem;
}

.home-about-blurb p {
    color: var(--description-color);
    font-size: 1.2rem;
}

.home-about-image {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
}

.home-about-image img {
    width: 80%;
    border: 0.2em black solid;
    border-radius: 77% 23% 70% 30% / 30% 30% 70% 70%;
}

.home-about-tech {
    display: flex;
    flex-direction: row;
    align-items: center; 
}


.home-about-tech>* {
    margin: 1rem;
    size: 20;
}

.home-about-tech-spacer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3rem;
    margin-left: 1rem;
}

.home-about-tech-spacer>* {
    margin: 0.5rem;
}

.icon-html {
    color: #f06529;
}

.icon-css {
    color: #2965f1;
}

.icon-js {
    color: #f0db4f;
}

.icon-react {
    color: #61dafb;
}

.icon-node {
    color: #339933;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95px;
}

.icon-container h2 {
    margin: 0 0 0 0;
}

.icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: white;
    border-radius: 50%;
    padding: 0.5em;
    box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.09);
}

@media only screen and (max-width: 490px) {
    .home-about-content-container {
        margin-top: 2rem;
        padding: 1rem;
        flex-direction: column;
    }

    .home-about-blurb {
        width: 70%;
        text-align: center;
        margin-bottom: 3rem;
    }

    .home-about-blurb h1 {
        font-size: 1.5rem;
    }

    .home-about-blurb p {
        font-size: 1rem;
    }

    .home-about-image {
        width: 80%;
        margin-bottom: 3rem;
    }

    .home-about-tech {
        justify-content: center;
        width: 100%;
    }

    .home-about-tech-spacer {
        margin: 0.5rem;
    }

    .icon-container {
        font-size: 0.5rem;
        width: 35px;
    }

    .icon-holder {
        height: 40%;
        margin: 0.3rem;
    }

    .home-about-tech-spacer h2 {
        font-size: 1rem;
        margin: 0.5;
    }

}
