:root {
    --header-color: rgb(66, 120, 245);
    --description-color: rgb(75, 78, 80);
    --background-color: #f5f5f5;
}

.projects-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-snap-align: start;
    scroll-margin-top: 150px;
}

.projects-title {
    width: 50%;
    display: grid;
    color: var(--header-color)
}

.project-info-left {
    transition: all 1s;
    filter: blur(5px);
    transform: translateX(-100%);
    display: flex;
    justify-content: center;
    align-items: space-between;
    width: 60%;
    margin: 1.5em;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0 1em rgb(209, 205, 205) ;
}

.appear-left {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.project-info-right {
    transition: all 1s;
    filter: blur(5px);
    transform: translateX(100%);
    display: flex;
    justify-content: center;
    align-items: space-between;
    width: 60%;
    margin: 1.5em;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0 1em rgb(209, 205, 205) ;
}

.appear-right {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.project-info-left > * {
    margin: 3em;
}

.project-info-right > * {
    margin: 3em;
}

.project-info-left img {
    width: 50%;
    height: auto;
    border-radius: 1rem;
}

.project-info-right img {
    width: 50%;
    height: auto;
    border-radius: 1rem;
}

.projects-info-blurb {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 30%;
    text-align: center;
}

.projects-info-blurb > * {
    margin: 0.5rem;
}

.projects-info-blurb p{
    color: var(--description-color)
}

.projects-links > button {
    margin: 1em;
    width: 8em;
    height: 2em;
    border-color: var(--header-color);
    background-color: white;
    cursor: pointer;
    color: var(--header-color);
    border-radius: 1em;
    font-size: 1rem;
    transition: 0.5s;
}

.projects-links > button:hover {
    background: linear-gradient(to left, var(--header-color) 34%, white 65%) right;
    background-size: 300% 100%;
    color: white;
}

.projects-links {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    text-align: center;
}

@media(prefers-reduced-motion) {
    .project-info-left {
        transition: none;
    }

    .project-info-right {
        transition: none;
    }
}

@media only screen and (max-width: 490px) {
    .project-info-left {
        flex-direction: column;
        width: 90%;
        align-items: center;
    }

    .project-info-right {
        flex-direction: column-reverse;
        width: 90%;
        align-items: center;
    }

    .project-info-left > * {
        padding: 1em;
        margin: 0;
        width: 100%;
    }

    .project-info-right > * {
        padding: 1em;
        margin: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .project-info-left {
        flex-direction: column;
        width: 90%;
        align-items: center;
    }

    .project-info-right {
        flex-direction: column-reverse;
        width: 90%;
        align-items: center;
    }

    .project-info-left > * {
        padding: 1em;
        margin: 0;
        width: 100%;
    }

    .project-info-right > * {
        padding: 1em;
        margin: 0;
        width: 100%;
    }

}

@media only screen and (max-width: 1300px) {

    .project-info-left {
        flex-direction: column;
        width: 90%;
        align-items: center;
    }

    .project-info-right {
        flex-direction: column-reverse;
        width: 90%;
        align-items: center;
    }

    .project-info-left > * {
        padding: 1em;
        margin: 0;
        width: 100%;
    }

    .project-info-right > * {
        padding: 1em;
        margin: 0;
        width: 100%;
    }

}